// #General body style
body {
  background: #fff;
  font: 14px/21px $body_font_family;
  color: #666666;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// #General horizontal rule style
hr {
  border: $hr_border;
  border-width: $hr_border_width;
  clear: both;
  margin: $hr_margin;
  height: 0;
}

.outline-override{
	width:auto !important;
	border:0 !important;
	box-shadow: none !important;
}

.dynamic-section{
	.big-container{
		padding:95px 0;
		background-color: #fff;
	}
	&.first-section{
		background-color: #fff;
		.big-container{
			padding-top:25px;
		}
	}
}

/* Nav */

.nav{
	position:fixed;
	width:100%;
	height:65px;
	margin-top:20px;
	background-color:rgba(18,47,80,.9);
	z-index: 9999;
	-webkit-box-shadow: 0px 0px 8px 5px rgba(0,0,0,.6);
    -moz-box-shadow: 0px 0px 8px 5px rgba(0,0,0,.6);
    box-shadow: 0px 0px 8px 5px rgba(0,0,0,.6);
	@include breakpoint(xs) {
		position:relative;
		margin-top:0;
		height:auto;
	 }
	@include breakpoint(sm) {
		position:relative;
		margin-top:0;
		height:auto;
	 }
	@include breakpoint(md) {
		
	}
	@include breakpoint(lg) {
		margin-top:20px;
		
	}
	
}

.logo{
	position: absolute;
	left:50%;
	margin-left:-512px;
	a{
		position:absolute;
		height:53px;
		width:311px;
		z-index:9999;
		@include breakpoint(xs) {
			margin:0 auto;
			width:100%;
			position:relative;
			height:auto;
			display:block;
			text-align: center;
		}
		@include breakpoint(sm) {
			margin:0 auto;
			width:100%;
			position:relative;
			height:auto;
			display:block;
			text-align: center;
		}

		@include breakpoint(md) {
			width:255px;
		}
		@include breakpoint(lg) {
			width:311px;
		}

	}
	img{
		margin-top:-9px;
		padding:35px 40px 35px 40px; 
		-webkit-box-shadow: 0px 0px 8px 5px rgba(0,0,0,.6);
	    -moz-box-shadow: 0px 0px 8px 5px rgba(0,0,0,.6);
	    box-shadow: 0px 0px 8px 5px rgba(0,0,0,.6);
		background-color:#122f50;

	}
	@include breakpoint(xs) {
		left:auto;
		margin-left:0px;
		position:relative;
		margin:auto;
	 }
	@include breakpoint(sm) {
		left:auto;
		margin-left:0px;
		position:relative;
		margin:auto;
	 }
	@include breakpoint(md) {
		margin-left:-415px;
	}
	@include breakpoint(lg) {
		margin-left:-512px;
	}

}

.menu{
	li{
		display:inline;		
	}
}

#menu-utility-menu{
	padding-left:50px;
	margin:5px 0 10px !important;
	@include breakpoint(sm) {
		text-align:center;
	}
	@include breakpoint(xs) {
		text-align:center;
	}
	li{
		font-size:15px;
		text-transform: uppercase;
		padding:0 15px 0 12px;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight:bold;
		letter-spacing:1px;
		&.current-page-ancestor{
			a{
				color:#a9c5e4;
			}
		}
		&.current_page_item{
			a{
				color:#a9c5e4;
			}			
		}
		&:last-child{
			padding-right:0;
		}
		@include breakpoint(xs) {
			font-size:11px;
			padding:0 3px;
		}
		@include breakpoint(sm) {
			font-size:12px;
		}
		@include breakpoint(md) {
			padding: 0 5px;
		}
		@include breakpoint(lg) {
			padding:0 15px 0 12px;
		}
	}
	a{
		color:#418de2;
		&:hover{
			color:#a9c5e4			
		}
		
	}
	@include breakpoint(xs) {
		padding-left:10px;
	}
	@include breakpoint(sm) {
		padding-left:10px;
	}
}

#menu-main-menu{
	padding-left:50px;
	@include breakpoint(xs) {
		position:relative;
		text-align:center;
		padding-left:10px;
		padding-bottom:20px;
	}
	@include breakpoint(sm) {
		position:relative;
		text-align:center;
		padding-left:10px;
		padding-bottom:10px;
	}
	li{
		position: relative;
		font-size:17px;
		padding:5px 12px 6px;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight:bold;
		letter-spacing:1px;
		&.current-page-ancestor{
			a{
				color:#a9c5e4;
			}
		}
		&.current_page_item{
			a{
				color:#a9c5e4;
			}			
		}
		&:last-child{
			//padding-right:0;
		}
		ul{
			margin:0;
			padding:0;
		}
		li{
			display:block !important;
			margin:0;
			padding:0 12px;
			text-align:left;
			a{
				display:block;
				padding:10px 0;
				color:#fff !important;
				border-bottom:1px solid #2267b5 !important;
			}
			&:last-child{
				a{
					border:0 !important;
					
				}
			}
		}


		@include breakpoint(xs) {
			float:none !important;
			font-size: 12px;
			padding: 0px 2px 6px;		
		}
		@include breakpoint(sm) {
			float:none !important;
			font-size: 15px;
			padding: 5px 7px 6px;		
    	}

		@include breakpoint(md) {
			padding: 0 5px;
			font-size:14px;
	
		}
		@include breakpoint(lg) {
			padding:5px 12px 6px;
			font-size:17px;
	
		}
	}
	a{
		color:#fff;
		text-transform: uppercase;
		&:hover{
			color:#fff !important;
		}
	}



}

#under-nav-text{
	position:fixed;
	width:100%;
	margin-top:84px;
	background-color:rgba(255,255,255,.85);
	z-index: 9998;
	p{
		margin:0;
		padding:9px 0 5px 60px;
		font-family: 'Open Sans Condensed', sans-serif;
		font-size:14px;
		font-weight:bold;
		a{
			color:#418de2;
			&:hover{
				color:#a9c5e4;
			}
		}
/*
		@include breakpoint(sm) {
			font-size:10px;
			padding:9px 0 5px 55px;
		}
*/
		@include breakpoint(md) {
			font-size:12px;
			padding:9px 0 5px 55px;
		}
		@include breakpoint(lg) {
			font-size:14px;
			padding:9px 0 5px 60px;
		}
	}
	@include breakpoint(xs) {
		display:none;
	}
	@include breakpoint(sm) {
		display:none;
	}
}

/* Hero Styles */

.big-hero{
	height:700px;
	position:relative;
	font-family: "Open Sans", sans-serif;
		

	.slide{
		display: none;
		width:100%;
		height:700px;
		background-repeat:no-repeat; 
		background-size:cover;
		background-position: center top;
		z-index:9500;
		@include breakpoint(xs) {
			height:400px;
		}
		@include breakpoint(sm) {
			height:400px;
		}
	}
	#hero-dot{
		width:100%;
		bottom:0;
		color:#fff;
		position:absolute; 
		text-align:center;
		z-index: 9980;
		font-family: Arial, sans-serif;
		span{
			padding:0 2px;
			font-size:50px;
			text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
             cursor: pointer;
		}
		.cycle-pager-active{
			color:#57acda;
		}
	}
	.hero-message{
		padding-top:350px;
		background-color:none;
		color:#fff;
		font-family: "Open Sans", sans-serif;
		h1{
			width:515px;
			color:#fff;
			font-size:50px;
			line-height:46px;
			font-family: "Open Sans", sans-serif;
			text-transform:uppercase;
			text-shadow: 1px 1px 4px black !important;
			@include breakpoint(xs) {
				padding:25px 0 15px 0;	
				font-size:26px;
				line-height:28px;
				width:100%;
			}
			@include breakpoint(sm) {
				padding:25px 0 15px 0;	
				font-size:26px;
				line-height:28px;
				width:100%;
			}
			@include breakpoint(xs) {
				padding:15px 15px 0 15px;	
				font-size:26px;
				line-height:28px;
			}
		}
		p{
			font-size:22px;
			line-height:28px;
			@include breakpoint(sm) {
				padding:0 15px 15px;	
				font-size:16px;
				line-height:22px;
			}
			@include breakpoint(xs) {
				padding:0 15px 15px;	
				font-size:16px;
				line-height:22px;
			}
		}
		a{
			margin-top:5px;
			color:#fff !important;
			box-shadow: 1px 1px 4px black !important;
		}

		@include breakpoint(xs) {
			padding:0;
			width:100%;
			top:auto;
			bottom:0;
			right:auto;
			text-align:center;
		}
		@include breakpoint(sm) {
			padding:0;
			width:100%;
			top:auto;
			bottom:0;
			right:auto;
			text-align:center;
		}
	}
	@include breakpoint(xs) {
		padding-top:0;
		height:383px;
	}
	@include breakpoint(sm) {
		padding-top:0;
		height:383px;
	}

}
.small-hero{
	height:500px;
	position:relative;
	.small-slide{
		width:100%;
		height:500px;
		background-repeat:no-repeat; 
		background-size:cover;
		background-position: center top;
		z-index:9500;


		.hero-message{
			padding-top:350px;
			background-color:none;
			color:#fff;
			font-family: "Open Sans", sans-serif;
			h1{
				color:#fff;
				font-size:50px;
				line-height:46px;
				font-family: "Open Sans", sans-serif;
				text-transform:uppercase;
				text-shadow: 1px 1px 4px black !important;
				@include breakpoint(sm) {
					padding:15px 15px 0 15px;	
					font-size:26px;
					line-height:28px;
				}
				@include breakpoint(xs) {
					padding:15px 15px 0 15px;	
					font-size:26px;
					line-height:28px;
				}
			}
			@include breakpoint(xs) {
				padding-top:0;
			}
			@include breakpoint(sm) {
				padding-top:0;
			}

		}

		.hero-quick-image{
			padding-top:285px;
			img{
				height:160px;
				border:6px solid #ddd;
				box-shadow: 2px 2px 5px 0px #8c877c;		
			}
		}
		.hero-quick-image-caption{
			font-family: 'Open Sans Condensed', sans-serif;
			font-size:14px;
			color:#fff;
			font-weight:bold;
			a{
				color:#fff;
			}
		}

		@include breakpoint(sm) {
			height:210px;
		}
		@include breakpoint(xs) {
			height:180px;
		}
			
	}
	@include breakpoint(xs) {
		height:180px;
		padding-top:0;
	}
	@include breakpoint(sm) {
		height:210px;
		padding-top:0;
	}
}


/* Layout Styles */

%dynamic-layout{
	h1{
		padding-top:5px;
		font-size:30px;
		color:#044366;
		line-height:normal;
	}
	h2{
		margin-bottom:10px;
		font-size:22px;
		line-height:30px;
		color:#6f6f6e;
		font-weight:300;
	}
	h3{
		font-size:20px;
		line-height:22px;
		color:#044366;
		@include breakpoint(xs) {
			margin-top:20px;
		}
		@include breakpoint(sm) {
			margin-top:20px;

		}
	}
	h4{
		
	}
	h5{
		
	}
	h6{
		font-size:15px;		
	}
	p{
		font-family: 'Open Sans', sans-serif;
		margin-bottom:10px;
	}
	a{
		color:#367dcd !important;
		&:hover{
			color:#122f50;
		}
	}
	img{
		width:95%;
		border:6px solid #ddd;
		box-shadow: 2px 2px 5px 0px #8c877c;		
		
	}
	.row{
		padding:25px 0;
	}
}

.home{
	a{
		&:hover{
			color:#122f50 ;
		}
	}
	.first-section{

	    position: relative !important;
	    z-index: 9998 !important;
	    margin-top: -48px !important;
		background-color:transparent !important;
		.big-container{
			padding-top:0 !important;
			background-image:none !important;
			background-color:transparent !important;
			
		}
	}
	.first{
		font-size:48px;
		line-height:54px;
		font-weight:bold !important;
	}
	.other{
		margin-bottom:0;
		font-size:15px;
		font-weight:bold !important;
	}
	.meta{
		font-size:12px;
		color:#aeada9;
	}
	.entry-content{
		margin-bottom:20px;
	}
	.calendar-events{
		padding:5px !important;
		p{
			padding:5px 0 !important;
		}
		h4{
			font-size:15px;
			font-weight:bold;
			color:#6f6f6e;
			text-transform: uppercase;
			border-bottom:1px solid #ccc;
		}
		h5{
			margin:0;
			padding:0;
			font-size:15px;
		}
		.meta{
			margin:0;
			line-height:14px;
		}
	}
	.row{
		padding:0;
	}
	
}

.sub-menu-in-content{
	li{
		a{
			text-transform: uppercase;
		}
		&.current_page_item{
			a{
				color:#a9c5e4 !important;
			}
		}
	}
	@include breakpoint(sm) {
		padding-bottom:20px !important;
	}
	@include breakpoint(xs) {
		padding-bottom:20px !important;
	}
}

.meta{
	font-size:12px;
	color:#aeada9;
}


.fullwidth{
	@extend %dynamic-layout;
}

.tabbedcontent{
	@extend %dynamic-layout;
	.tab-container{
		position: relative;
		background-color: rgba(247,247,247,.9);
		z-index: 9998;
	}
	.tabs{
		margin: 0px;
		padding: 0px;
		list-style: none;
		display:table;
		table-layout:fixed;
		width: 100%; /* [3] */
		li{
			background: none;
			color: #6f6f6e;
			display: table-cell;
			padding: 15px 15px;
			cursor: pointer;
			text-align:center;
			font-family: 'Open Sans Condensed', sans-serif;
			font-size:17px;
			font-weight:bold;
			&.current{
				background: #fff;
				color: #367dcd;
			}
			@include breakpoint(xs) {
				font-size:12px;
				padding:15px 10px;
			}
		}
		
	}
	.tab-content{
		display: none;
		margin-top:95px;
		background: #fff;
		&.current{
			display: inherit;
		}
	}

	.tab-subnav{
		li{
			margin:0;
			padding:5px;
			border-bottom:1px solid #ccc;	
			&:last-child{
				border:0;
			}		
		}
		a{
			display:block;
			font-family: 'Open Sans Condensed', sans-serif;
			font-weight:bold;
			color:#367dcd;
			&:hover{
				color:#122f50;
			}
		}
		
	}
	
}

.two-column{
	@extend %dynamic-layout;
	p{
		font-size:15px;
		font-family: 'Open Sans', sans-serif;
	}
	figcaption{
		background-color:rgba(251,169,25,.85);
	}
	ul{
		list-style-type: disc;
	}
}

.two-column-large-small{
	@extend %dynamic-layout;

	.three{
		h1{
			margin-bottom:0;
			padding:5px !important;
			font-size:17px !important;
			color:#122f50 !important;
			font-weight:bold !important;
			border-bottom:2px solid #122f50;
		}
		p{
			padding:5px;
		}
	}

	h2{
	}
	h3{
		font-size:18px;
		color: #122f50;
	}

	ul{
		margin:0;
		padding:0;
		li{
			margin:0;
			padding:5px;
			border-bottom:1px solid #ccc;	
			&:last-child{
				border:0;
			}		
		}
		a{
			display:block;
			font-family: 'Open Sans Condensed', sans-serif;
			font-weight:bold;
			color:#367dcd;
			&:hover{
				color:#122f50;
			}
		}
		
	}
}

.two-column-small-large{
	@extend .two-column-large-small;
}


.three-columns{
	@extend %dynamic-layout;
	p{
		font-size:15px;
		font-family: 'Open Sans', sans-serif;
	}
	img{
		width:95%;
		border:6px solid #ddd;
		box-shadow: 2px 2px 5px 0px #666;		
		
	}
}

.four-columns{
	@extend %dynamic-layout;
}

.space{
	hr{
		 border: solid transparent;

	}
	@include breakpoint(xs) {
		display: none;
	}
	@include breakpoint(sm) {
		display: none;
	}

}

.gallery{
	padding:20px 0;
	a{
	}
	img{
		margin:10px 0;
		width:95%;
		border:6px solid #ddd;
		box-shadow: 2px 2px 5px 0px #8c877c;
	}
}

/* Form */
.gform_wrapper {
	.gform_fields{
		padding:0 !important;	
	}
	.gsection{
		padding:25px 0 0 !important;
		border:0 !important;	
		&:first-child{
			padding:0 !important;
		}	
	}
	.gsection_title{
		padding-left:5px !important;		
		clear:both !important;
		line-height:normal !important;
	}
	li{
		margin:0 !important;
		border:0 !important;
	}
	label{
// 		display:block;
		padding: 15px 0 5px;
		clear:both;
	}
	.gfield_label{
		display:none;
		padding: 10px 0 5px;
	}
	
	.gfield_radio{
		margin:0;
		padding:0;
		clear:both;
		li{
			float:left;
			display:inline !important;
		}
		label{
			font-family: arial, sans-serif;
			padding:0 20px 0 10px;
		}
	}
	.left-field{
		float:left !important;
		width:35%;
		input[type="text"]{
			width:90% !important;
		}
	}
	.left-field-radio {
		.gfield_label{
			padding-top:15px;
			clear:	both;
			display:block;
		}
		
	}
	.right-field{
		float:left !important;
		width:60%;
		input[type="text"]{
			width:98%;
			
		}
	}
	.right-field-dropdown{
		.gfield_label{
			padding-top:15px;
			display:block;
			clear:both;
		}
	}
	.clear-multi{
		clear:both;
		padding-top:20px;
	}
	h2{
		margin-top:20px;
		font-size:20px !important;
	}
	select{
		float:left;
	}
	textarea{
		margin:0;
		width:95% !important;
	}
	input[type="submit"]{
		float:none;
		text-transform: uppercase;
	}
	.gform_footer{
		display:block;
		padding:20px 0 0;
		clear:both;
	}
	.other-radio{
		label{
			
		}
	}
	.other-big-radio{
		label{
			display:block;
		}
		.ginput_container_radio{
			label{
				display:inline !important;
			}
		}
		
	}
	.option-textarea{
		clear:both;
	}
	#input_1_17_other{
		display:inline;
		margin-left:10px;
	}
	#input_1_13_1, #input_1_13_2, #input_1_13_3, #input_1_13_4_container, #input_1_13_5{
		margin-bottom:0 !important;
		margin-top:20px !important;
	}
	#input_1_13_3_container, #input_1_13_4_container, #input_1_13_5_container, #input_1_13_4{
		display:block;
		clear:both;
	}
	#input_1_13_4_label{
		display:block;
		padding:0 !important;
	}
	#input_1_13_4{
		margin-bottom:0 !important;
	}
}

/* News */
.page-template-page-news{
	.dynamic-section{
		.big-container{
			padding-bottom:0 !important;
		}
	}
}


#footer{
	.big-container{
		padding:45px 0;
		color:#e0eef3;
		background-color:rgb(35,94,160);
		background-image:url(../images/bg_shadow.png);
		background-repeat:repeat-x;
		strong{
			color:#e0eef3
		}
	}
	a{
		color:#f3eba5;
	}
	.menu{
		margin:0;
		padding:0;
		ul{
			margin:0;
			padding:0;
		}
		li{
			display:block !important;
			margin:0;
			padding:15px 0 0;
			text-transform: uppercase;
			a{
				font-size:13px;
				font-weight:bold;
				color: #fff;
					&:hover{
						color:#f7f0ad;
					}
			}
			li{
				padding:5px 0 0;
				text-transform: none;
				a{
					font-weight:normal;
					padding-left:5px;
				}
			}
		}
		.current_page_item{
			a{
				color:#6091c8;
			}
			li{
				a{
					color:#fff;
				}
			}
		}
	}
	.footer-social{
		vertical-align: middle;
		padding-left:5px;
	}
}

#accredited{
	.big-container{
		padding:65px 0 100px;
		background-color:#adb2b9;
		background-image:url(../images/bg_shadow.png);
		background-repeat:repeat-x;
		.left{
			font-size:18px;
			font-weight:bold;
		}
		
	}
}


